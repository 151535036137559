.canvas{
    display: block;
    position: absolute;
    top:0px;
    left: 0px;
    width: 500px;
    height: 500px;
    background-color: gray;
    z-index: -1;
}

.point{
    display: block;
    position: inherit;
    top:0px;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #5eff00;
}

.endPoint{
    display: block;
    position: inherit;
    top:0px;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: red;
}


.startPoint{
    display: block;
    position: inherit;
    top:0px;
    left: 0px;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #0066ff;
    z-index: 10;
}

.track{
    display: block;
    position: inherit;
    top:0px;
    left: 0px;
    width: 100px;
    height: 1px;
    border-radius: 10px;
    background-color: #07003c;
    transform-origin: top left;
    text-align: center;
}

.accuracy{
    display: block;
    position: inherit;
    top:0px;
    left: 0px;
    width: 30px;
    height: 30px;
    border-radius: 1000px;
    border: rgb(0, 74, 185) solid 2px;
    background-color: rgba(0, 102, 255, 0.18);
}



button{
    width: 100px;
    height: 100px;
}